import request from '@/plugins/axios';
// function pickTrue(obj) {
//   const newObj = {
//     ...obj,
//   };
//   Object.keys(newObj).forEach((item) => {
//     if (!newObj[item] && newObj[item] !== 0) {
//       delete newObj[item];
//     }
//   });
//   return newObj;
// }
/* ----------【all】分页车辆------------ */
export const getEscortOld = (data) => {
  return request.post(`/resource/escort/list`, data).then((res) => {
    return res;
  });
};
export const getEscort = (data) => {
  return request.post(`/resource/middle/escort/list`, data).then((res) => {
    return res;
  });
};
export const addEscort = (data) => {
  return request.post('/resource/escort/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailEscort = (data) => {
  return request.get('/resource/escort/detail', data).then((res) => {
    return res;
  });
};
export const editEscort = (data) => {
  return request.post('/resource/escort/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteEscort = (data) => {
  return request.post('/resource/escort/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteEscort = (data) => {
  return request.post('/resource/escort/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
// 车辆状态数量统计
export const escortStateTotal = (data) => {
  return request.post('/resource/escort/state/total', data, { showLoading: true }).then((res) => {
    return res;
  });
};

