<template>
  <div class="left-panel-wrap">
    <div v-if="showTab" class="tab-wrap">
      <div v-for="(tab, index) in tabs" :key="index" :class="index === activeTab ? 'active-tab flex-c-c' : 'flex-c-c'">
        <span @click="changeTab(index)">{{ tab.label }}</span>
      </div>
    </div>
    <el-input v-model.trim="userName" clearable placeholder="搜索用户名称" @focus="focusInput" @keyup.enter.native="search">
      <i slot="suffix" class="el-input__icon el-icon-search cursor" @click="search" />
    </el-input>
    <div class="company-wrap" :style="{ height: showTab ? 'calc(100% - 130px)' : 'calc(100% - 90px)' }">
      <div v-for="(item, index) in companyList" :key="index" class="company-item " :class="{ active: activeId === item.companyId }" @click="changeCompany(item,index)">
        <!-- <TextToolTip :text="item.companyName" /> -->
        <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="item" :allow-to-detail="false" />
      </div>
    </div>
    <div class="fixed-toggle">
      <div class="arrow" :class="isFold ? 'arrow-right' : 'arrow-left'" @click="foldClick">
        <i :class="['iconfont',isFold ? 'icon-menu-unfold-line' : 'icon-menu-fold-line']" />
      </div>
    </div>
    <div class="pagination-wrap">
      <el-pagination
        :class="{'small-pagination':isFold}"
        small
        layout="prev, pager, next"
        :pager-count="5"
        :total="pagination.total"
        :page-size="pagination.size"
        :current-page="pagination.page"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
// import TextToolTip from '@/components/TextToolTip';
// import { getCompanyList, getCompanyListSelf } from '@/api/company';
import { goodsUserList, transUserList } from '@/api/userManageMent';
export default {
  // components: { TextToolTip },
  props: {
    // 是否是收起状态
    isFold: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 公司type 1:货主 2:承运商 all全部
    companyType: {
      type: [String, Number],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      tabs: [
        { label: '货主端' },
        { label: '承运商端' },
      ],
      activeTab: 0,
      activeId: 0,
      activeType: 1,
      userName: '',
      companyList: [],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },
    };
  },
  computed: {
    showTab() {
      return this.companyType === 'all';
    },
  },
  created() {
    this.getCompanyListData();
  },

  methods: {
    changeTab(index) {
      if (this.activeTab === index) {
        return;
      }
      this.activeTab = index;
      this.getCompanyListData('search');
    },
    getCompanyListData(type) {
      // if (type && type !== 'search') {
      //   this.activeType = type;
      // }
      const params = {
        current: this.pagination.page,
        size: this.pagination.size,
        isSearchRel: 1, // 是否只查客服绑定用户 1是
        // userName: this.userName,
      };
      // 超管拉全部PT007 其余拉自己相关企业 （后期如果新增哪些角色需要查看所有 和超管方式一致）
      // const api = ['PT007'].includes(this.$store.state.common.AUV_ROLE_CODE) ? getCompanyList : getCompanyListSelf;
      // const api = ['PT007'].includes(this.$store.state.common.AUV_ROLE_CODE) ? goodsUserList : transUserList;
      const apiMap = {
        // 1: goodsUserList,
        // 2: transUserList,
        // 'all': goodsUserList,
        0: {
          1: goodsUserList,
          2: transUserList,
          'all': goodsUserList,
        },
        1: {
          'all': transUserList,
        },
      };
      apiMap[this.activeTab][this.companyType](params).then(res => {
        // const newRecords = res.records || [];
        const newRecords = [];
        for (let i = 0; i < res.records.length; i++) {
          newRecords.push({
            companyName: res.records[i].titleName || '-',
            companyId: res.records[i].userId,
            ...res.records[i],
          });
        }
        this.companyList = newRecords || [];
        if (type !== 'search' && this.companyList.length) {
          this.activeId = this.companyList[0]?.companyId || null;
          this.$emit('leftCompanyData', this.companyList ? this.companyList[0] : []);
        }
        this.pagination = {
          page: res.current,
          size: res.size,
          total: res.total,
        };
      });
    },
    search() {
      this.pagination.page = 1;
      this.getCompanyListData('search');
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getCompanyListData('search');
    },
    focusInput() {
      this.$emit('update:isFold', false);
    },
    changeCompany(data, index) {
      this.activeId = data.companyId;
      this.$emit('changeCompany', data);
    },
    foldClick() {
      this.$emit('update:isFold', !this.isFold);
    },
  },
};

</script>
<style lang='scss' scoped>
.left-panel-wrap{
  position: relative;
  background: #fff;
  padding: 24px 30px 15px 15px;
  border-right: 1px solid #EDF0F5;
  .tab-wrap {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    margin-bottom: 20px;
    > div {
      // width: 50%;
      cursor: pointer;
    }
    .active-tab {
      color:rgba(67, 133, 255, 1);
      font-weight: bolder;
      border-radius: 2px 2px 0px 0px;
      position: relative;
    }
    .active-tab:after {
      content: "";
      display: block;
      width:100%;
      height: 3px;
      background: rgba(67, 133, 255, 1);
      position: absolute;
      border-radius: 1px;
      bottom: -10px;
    }
  }
  .company-wrap{
    height: calc(100% - 90px);
    overflow-y: scroll;
    margin-top: 24px;
    padding-right: 24px;
    margin-right: -30px;
    .company-item{
      // padding: 15px;
      padding: 8px 0 8px 8px;
      cursor: pointer;
    }
    .active{
      background: #DFEAFF;
      border-radius: 4px;
    }
  }
  .fixed-toggle{
    .arrow {
      position: absolute;
      width: 20px;
      padding:8px 10px;
      background: #ffffff;
      cursor: pointer;
      z-index: 9;
      top:24px;
      margin: auto;
      transform: translateZ(100px);
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #5a6073;
      }
      span{
        display: inline-block;
        font-size: 12px;
        margin-top: 4px;
        line-height: 17px;
      }
    }
    .arrow-left{
      text-align: center;
      right: -1px;
      box-shadow: -2px 0 4px 0 rgb(0 0 0 / 10%);
      border-radius: 4px 0 0 4px;
      height: 36px;
      display: flex;
      align-items: center;
    }
    .arrow-right {
      width: 20px;
      right: -20px;
      box-shadow: 4px 0 4px 0 rgb(0 0 0 / 10%);
      border-radius: 0 4px 4px 0;
      // color: #006CFF;
      // i {
      //   color: #006CFF;
      // }
    }
  }
  .pagination-wrap{
    display: flex;
    justify-content: center;
    margin-top: 5px;
    .el-pagination{
      display: flex;
      align-items: center;
    }
    .small-pagination{
      padding: 0;
      ::v-deep{
        button{
          padding: 0;
          min-width: 12px;
        }
        .number{
          margin: 0;
          padding: 0;
          min-width: 12px;
          line-height: 22px
        }
        .active {
          background-color: transparent !important;
          color: #4385FF;
        }
      }
    }
  }
}
</style>
