<template>
  <el-drawer
    :title="drawerTitle"
    custom-class="g-drawer"
    :wrapper-closable="false"
    :visible="drawerVisible"
    size="600px"
    @open="open"
    @close="close"
  >
    <div class="g-drawer-c">
      <el-form ref="form" :model="drawerForm" :rules="drawerRules" :disabled="formDisabled" label-position="right" label-width="138px">
        <el-form-item label="关联调度" prop="ext1">
          <div style="line-height: 15px;width: 70%;">
            <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="companyInfo" :show-bg="true" />
          </div>
        </el-form-item>
        <el-form-item label="姓名" prop="driverName">
          <!-- :disabled="drawerType!=='add'" -->
          <el-input v-model="drawerForm.driverName" placeholder="请输入" maxlength="30" clearable />
        </el-form-item>
        <el-form-item label="联系电话" prop="driverPhone">
          <el-input v-model="drawerForm.driverPhone" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCode">
          <el-input v-model="drawerForm.idCode" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="身份证正、反照片" prop="idCodePic">
          <ImageUpload
            :model-value.sync="drawerForm.idCodePic"
            placeholder="身份证正反面照片"
            :limit="2"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <el-form-item label="从业资格证有效期止" prop="permitExpireTime">
          <el-date-picker
            v-model="drawerForm.extMap.permitExpireTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="从业资格证照片" prop="permitPic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.permitPic"
            placeholder="从业资格证照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="drawerForm.remark" type="textarea" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="danger" @click="deleteClick">删除</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="changeEdit">编辑</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { validateTel, validateID } from '@/utils/validateRule';
import { addEscort, editEscort, getDetailEscort, deleteEscort } from '@/api/escort';
export default {
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {
          // companyId: null,
        };
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增押运员',
        'edit': '编辑押运员',
        'detail': '查看押运员',
      },
      drawerForm: {
        driverName: '',
        idCodePic: '',
        extMap: {
          permitExpireTime: '',
          permitPic: '',
        },
      },
      drawerRules: {
        driverName: [
          { required: true, message: '请输入姓名', trigger: 'change' },
          // { validator: validateName, trigger: 'change' },
        ],
        driverPhone: [
          { required: true, message: '请输入联系电话', trigger: 'change' },
          { validator: validateTel, trigger: 'change' },
        ],
        idCode: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
          { validator: validateID, trigger: 'change' },
        ],
      },
    };
  },

  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
  },

  watch: {
    // 监听路由变化，如果发生变化则关闭drawer
    '$route': function(to, from) {
      this.drawerVisible = false;
    },
  },
  methods: {
    open() {
      this.drawerTypeDesc = this.drawerType;
      if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
        this.getDetail();
      }
    },
    close() {
      this.drawerForm = {
        idCodePic: '',
        extMap: {
          permitExpireTime: '',
          permitPic: '',
        },
      };
      this.$refs.form.resetFields();
      this.$emit('update:drawerVisible', false);
    },
    getDetail() {
      getDetailEscort({ escortId: this.drawerId }).then(res => {
        this.drawerForm = res || {};
      });
    },
    deleteClick() {
      const params = {
        id: this.drawerId,
      };
      deleteEscort(params).then(res => {
        this.$baseMessage('删除成功!', 'success');
        this.$emit('drawerSubmit');
        this.close();
      });
    },
    changeEdit() {
      this.drawerTypeDesc = 'edit';
    },
    submit() {
      // type 获取type 分别对应api提交
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            const drawerForm = JSON.parse(JSON.stringify(this.drawerForm));
            const apiMap = {
              'add': addEscort,
              'edit': editEscort,
            };
            const res = await apiMap[this.drawerTypeDesc](drawerForm);
            if (res) {
              this.$baseMessage(`${this.drawerTitle}成功!`, 'success');
              this.$emit('drawerSubmit');
              this.close();
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
