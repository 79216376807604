import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
const prefix = '/auv';
/* 个人用户管理模块 */
/* 货主列表 */
export const goodsUserList = (data) => {
  return request.post(`${prefix}/goods/user/list`, pickTrue(data)).then((res) => {
    return res;
  });
};
/* 货主详情 */
export const goodsUserDetail = (data) => {
  return request.post(`${prefix}/goods/user/detail`, data).then((res) => {
    return res;
  });
};
/* 货主新增 */
export const goodsUserAdd = (data) => {
  return request.post(`${prefix}/goods/user/add`, data).then((res) => {
    return res;
  });
};
/* 货主手机号修改 */
export const goodsUserPhoneUpdate = (data) => {
  return request.post(`${prefix}/goods/user/phone/update`, data).then((res) => {
    return res;
  });
};
/* 货主密码重置 */
export const goodsUserPasswdUpdate = (data) => {
  return request.post(`${prefix}/goods/user/passwd/update`, data).then((res) => {
    return res;
  });
};
/* 货主关联客服人员 */
export const goodsUserCustomerUpdate = (data) => {
  return request.post(`${prefix}/goods/user/customer/update`, data).then((res) => {
    return res;
  });
};
/* 资质列表 */
export const goodsUserQualificationList = (data) => {
  return request.post(`${prefix}/goods/user/qualification/list`, data).then((res) => {
    return res;
  });
};
/* 资质添加 */
export const goodsUserQualificationAdd = (data) => {
  return request.post(`${prefix}/goods/user/qualification/add`, data).then((res) => {
    return res;
  });
};
/* 发起资质认证 */
export const goodsUserQualificationDetailCheck = (data) => {
  return request.post(`${prefix}/goods/user/qualification/detail/check`, data).then((res) => {
    return res;
  });
};

/* 资质详情 */
export const goodsUserQualificationDetail = (data) => {
  return request.post(`${prefix}/goods/user/qualification/detail`, data).then((res) => {
    return res;
  });
};
/* 资质修改 */
export const goodsUserQualificationUpdate = (data) => {
  return request.post(`${prefix}/goods/user/qualification/update`, data).then((res) => {
    return res;
  });
};
/* 资质删除 */
export const goodsUserQualificationDelete = (data) => {
  return request.post(`${prefix}/goods/user/qualification/delete`, data).then((res) => {
    return res;
  });
};
/* 关注我的人列表 */
export const goodsUserGroupRelateMeList = (data) => {
  return request.post(`${prefix}/goods/user/group/relate/me/list`, data).then((res) => {
    return res;
  });
};
/* 我的同事列表 */
export const goodsUserWorkerList = (data) => {
  return request.post(`${prefix}/goods/user/worker/list`, data).then((res) => {
    return res;
  });
};
/* 发起实名认证 */
export const goodsUserDetailCheck = (data) => {
  return request.post(`${prefix}/goods/user/detail/check`, data).then((res) => {
    return res;
  });
};
/* 编辑我的名片 */
export const goodsUserCardUpdate = (data) => {
  return request.post(`${prefix}/goods/user/card/update`, data).then((res) => {
    return res;
  });
};

/* 司机列表 */
export const transUserDriverList = (data) => {
  return request.post(`${prefix}/trans/user/driver/list`, data).then((res) => {
    return res;
  });
};
/* 司机关联承运商列表 */
export const transUserDriverCreatorList = (data) => {
  return request.post(`${prefix}/trans/user/driver/creator/list`, data).then((res) => {
    return res;
  });
};
/* 承运商列表 */
export const transUserList = (data) => {
  return request.post(`${prefix}/trans/user/list`, pickTrue(data)).then((res) => {
    return res;
  });
};
/* 承运商新增 */
export const transUserAdd = (data) => {
  return request.post(`${prefix}/trans/user/add`, data).then((res) => {
    return res;
  });
};
/* 用户信息查询 */
export const transUserBaseInfo = (data) => {
  return request.get(`${prefix}/trans/user/base/info?userId=${data.userId}`).then((res) => {
    return res;
  });
};
/* 承运商手机号修改 */
export const transUserPhoneUpdate = (data) => {
  return request.post(`${prefix}/trans/user/phone/update`, data).then((res) => {
    return res;
  });
};
/* 承运商密码重置 */
export const transUserAccountPasswdUpdate = (data) => {
  return request.post(`${prefix}/trans/user/account/passwd/update`, data).then((res) => {
    return res;
  });
};
/* 承运商关联客服人员 */
export const transUserCustomerUpdate = (data) => {
  return request.post(`${prefix}/trans/user/customer/update`, data).then((res) => {
    return res;
  });
};
/* 承运商发起实名认证 */
export const transUserDetailCheck = (data) => {
  return request.post(`${prefix}/trans/user/detail/check`, data).then((res) => {
    return res;
  });
};

/* 资质列表 */
export const transportUserQualificationList = (data) => {
  return request.post('/transport/auv/qualification/list', data).then((res) => {
    return res;
  });
};
/* 资质添加 */
export const transportUserQualificationAdd = (data) => {
  return request.post('/transport/auv/qualification/add', data).then((res) => {
    return res;
  });
};
/* 发起资质认证 */
export const transUserQualificationDetailCheck = (data) => {
  return request.post(`${prefix}/trans/user/qualification/detail/check`, data).then((res) => {
    return res;
  });
};
/* 资质详情 */
// export const goodsUserQualificationDetail = (data) => {
//   return request.post(`${prefix}/goods/user/qualification/detail`, data).then((res) => {
//     return res
//   })
// }
/* 资质修改 */
export const transportUserQualificationUpdate = (data) => {
  return request.post('/transport/auv/qualification/update', data).then((res) => {
    return res;
  });
};
/* 资质删除 */
export const transportUserQualificationDelete = (data) => {
  return request.post('/transport/auv/qualification/delete', data).then((res) => {
    return res;
  });
};
/* 关注我的人列表 */
export const transUserGroupRelateMeList = (data) => {
  return request.post(`${prefix}/trans/user/group/relate/me/list`, data).then((res) => {
    return res;
  });
};
/* 我的关注人员列表 */
export const transUserGroupRelateOtherList = (data) => {
  return request.post(`${prefix}/trans/user/relate/other/list`, data).then((res) => {
    return res;
  });
};
/* 我的同事列表 */
export const transUserWorkerList = (data) => {
  return request.post(`${prefix}/trans/user/worker/list`, data).then((res) => {
    return res;
  });
};
/* 编辑我的名片 */
export const transUserCardUpdate = (data) => {
  return request.post(`${prefix}/trans/user/card/update`, data).then((res) => {
    return res;
  });
};
/* 实名认证模块 */
/* 获取用户实名认证审核列表 */
export const checkUserDetailCheckList = (data) => {
  return request.post(`${prefix}/check/user/detail/check/list`, data).then((res) => {
    return res;
  });
};
// 获取用户实名认证审核详情
export const checkUserDetailCheckDetail = (data) => {
  return request.post(`${prefix}/check/user/detail/check/detail`, data).then((res) => {
    return res;
  });
};
// 用户实名认证审核
export const checkUserDetailCheck = (data) => {
  return request.post(`${prefix}/check/user/detail/check`, data).then((res) => {
    return res;
  });
};

/* 业务资质审核模块 */
/* 获取资质认证审核列表 */
export const checkQualificationCheckList = (data) => {
  return request.post(`${prefix}/check/qualification/check/list`, data).then((res) => {
    return res;
  });
};
// 获取用户实名认证审核详情
export const checkQualificationCheckDetail = (data) => {
  return request.post(`${prefix}/check/qualification/check/detail`, data).then((res) => {
    return res;
  });
};
// 资质认证审核
export const checkQualificationCheck = (data) => {
  return request.post(`${prefix}/check/qualification/check`, data).then((res) => {
    return res;
  });
};
